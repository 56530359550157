import { hrOverviewStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useEffect, useState } from "react";
import NotificationComponent from "../hrDashboardComponents/NotificationComponent";
import * as isoWeek from "dayjs/plugin/isoWeek";
import dayjs from "dayjs";
// import { DateRangeSelector } from "../hrDashboardComponents/DateRangeSelector";
import SummaryPie from "../hrDashboardComponents/dashboardComponents/SummaryPie";
import SummaryLine from "../hrDashboardComponents/dashboardComponents/SummaryLine";
import ResponsesLog from "../hrDashboardComponents/dashboardComponents/ResponsesLog";
import SummaryBarGroup from "../hrDashboardComponents/dashboardComponents/SummaryBarGroup";
import Loading from "../hrDashboardComponents/dashboardComponents/LoadingScreen";
import { useAuth0 } from "@auth0/auth0-react";
import { getDashboard } from "../apiHelper";
import { convertMonthNumberToName, scoreToMessage } from "../helperFunctions";
dayjs.extend(isoWeek);

function Dashboard() {
  const classes = hrOverviewStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const open = Boolean(anchorEl);

  const [dashboardData, setDashboardData] = useState({});
  const [latestResponses, setLatestResponses] = useState({});

  const [loaded, setLoaded] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAccessTokenSilently()
      .then((t) => {
        getDashboard(t, { today: new Date() }).then((d) => {
          setDashboardData(d);
          setLatestResponses(d.recentAnswers);
          setLoaded(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (loaded) {
    return (
      <Box className={classes.hrOverviewContainer}>
        <Box className={classes.headingRow}>
          <Typography variant="h1">Dashboard</Typography>
          <Box className={classes.hrBarRight}>
            <Box className={classes.arrowContainer}>
              {/* <NotificationsIcon onClick={handleClick} color="secondary" /> */}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <NotificationComponent handleClose={handleClose} />
              </Menu>
            </Box>
            {/* <DateRangeSelector range={range} setRange={setRange} /> */}
          </Box>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.hrRowOuter}>
            <Box className={classes.hrRow}>
              <SummaryPie
                value={Math.round(dashboardData.annualAverage?.[0]?.qty, 1)}
                max={10}
                title="Annual Average Score"
                subtitle={scoreToMessage(
                  Math.round(dashboardData.annualAverage?.[0]?.qty, 1)
                )}
              />
              <SummaryPie
                value={dashboardData.answersToday?.[0]?.count}
                max={dashboardData.totalPeople}
                title="Participation Today"
                subtitle="Looking a bit low!"
              />
              <SummaryPie
                value={dashboardData.answersToday?.[0]?.qty}
                max={10}
                title="Today's Score"
                subtitle={scoreToMessage(dashboardData.answersToday?.[0]?.qty)}
              />
            </Box>
          </Box>
          <Box className={classes.hrRowOuter}>
            <Box className={classes.hrRow}>
              <ScoreTrendLine dashboardData={dashboardData} />
              <ParticipationTrendLine dashboardData={dashboardData} />
            </Box>
          </Box>
          <Box className={classes.hrRowOuter}>
            <Box className={classes.thirdRowLeft}>
              <SummaryBarGroup groupTitle={"People using the Hotline"} />
              <SummaryBarGroup
                groupTitle={"Amount of bookings to HR"}
                valuesGreen={dashboardData.bookingsThisWeek?.[0]?.count}
                valuesBlue={dashboardData.bookingsLastWeek?.[0]?.count}
              />
            </Box>
            <Box className={classes.thirdRowRight}>
              <ResponsesLog
                data={latestResponses}
                setLatestResponses={setLatestResponses}
              />
            </Box>
          </Box>
          {/* <Box className={classes.hrRowOuter}></Box> */}
          {/* <SummaryBarVertical /> */}
        </Box>
      </Box>
    );
  }
  return <Loading />;
}

const ScoreTrendLine = (props) => {
  const { dashboardData } = props;
  return (
    <SummaryLine
      type="grow"
      title="6 Months Trend"
      dataGreen={dashboardData?.answersByMonth?.map((answer) => answer.qty)}
      dataBlue={dashboardData?.priorSixMonths?.map((answer) => answer.qty)}
      labels={dashboardData?.answersByMonth?.map((answer) =>
        convertMonthNumberToName(answer._id)
      )}
    />
  );
};
const ParticipationTrendLine = (props) => {
  const { dashboardData } = props;

  return (
    <SummaryLine
      title="Participation Trends"
      dataGreen={dashboardData?.answersByMonth?.map((answer) => answer.count)}
      dataBlue={dashboardData?.priorSixMonths?.map((answer) => answer.count)}
      labels={dashboardData?.answersByMonth?.map((answer) =>
        convertMonthNumberToName(answer._id)
      )}
    />
  );
};

export default Dashboard;
